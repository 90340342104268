import {RouteRecordRaw} from "vue-router";
import {MessageKey} from "../../i18n";

const RouterMiddleware = () => import('../../components/RouterMiddleware.vue') // 路由中间页

export const enum RoutePathEnum {
  Home = "index",                 // 首页
  Asserts = "assets",            // 资产管理
  Withdraw = "withdraw",          // 同名提款
  Payout = 'payout',              // 全球付款
  Exchange = "exchange",          // 货币汇兑
  Transactions = "transactions",  // 交易查询
  Financing = "financing",        // 融资服务
  Rights = "rights",              // 权益中心
  Settings = "settings",          // 管理设置
}

// 是否显示参考 https://joyspace.jd.com/sheets/tWttC3WKtLHlwBF8zeSR 前端显示Sheet
export const AccountOpeningInterceptorRoutes = [
  // RoutePathEnum.Asserts,
  RoutePathEnum.Withdraw,
  'withdraw.accountList', 
  'withdraw.accountAddEdit', 
  RoutePathEnum.Payout,
  'Payout.PayeeManagement',
  'Payout.AddPayee',
  RoutePathEnum.Exchange,
  // RoutePathEnum.Transactions,
  // RoutePathEnum.Financing,
  // RoutePathEnum.Rights,
  // RoutePathEnum.Settings,
]

export const IsAdminInterceptorRoutes = [
  RoutePathEnum.Withdraw,
  'withdraw.accountList', 
  'withdraw.accountAddEdit', 
  RoutePathEnum.Payout,
  'Payout.PayeeManagement',
  'Payout.AddPayee',
  RoutePathEnum.Asserts,
  'assets.accountList', 
  'accountDetail', 
  RoutePathEnum.Exchange,
  RoutePathEnum.Transactions,
  RoutePathEnum.Financing,
  RoutePathEnum.Rights,
  RoutePathEnum.Settings,
  RoutePathEnum.Home
]

// 路由路径映射
export const RoutePathMap = {
  Home: {
    path: `/${RoutePathEnum.Home}`,
    name: RoutePathEnum.Home,
    meta: { title: "nav." + RoutePathEnum.Home  }
  },
  Assets: { // 资产管理
    path: `/${RoutePathEnum.Asserts}`,
    name: RoutePathEnum.Asserts,
    meta: { title: "nav." + RoutePathEnum.Asserts }
  },
  AssetsAccountList: { // 资产管理 - 全球收款账户
    path: `/${RoutePathEnum.Asserts}/accountList`, // 完整路径
    name: 'assets.accountList', // vue-rouer 路由name
    meta: { title: 'assets.accountList' } // title需和zh-cn.json的key对应
  },
  AssetsAccountDetail: { // 资产管理 - 全球收款账户 - 账户详情
    path: `/${RoutePathEnum.Asserts}/accountList/accountDetail/:accountNo`, // 完整路径
    name: 'accountDetail', // vue-rouer 路由name
    meta: { title: 'assets.accountDetail' }
  },
  Exchange: { // 货币汇兑
    path: `/${RoutePathEnum.Exchange}`,
    name: RoutePathEnum.Exchange,
    meta: { title: "nav." + RoutePathEnum.Exchange }
  },
  EquityCenter: { // 权益中心
    path: '/equity',
    name: RoutePathEnum.Rights,
    meta: { title: "nav." + RoutePathEnum.Rights }
  },
  Withdraw: { // 同名提款
    path: `/${RoutePathEnum.Withdraw}`,
    name: RoutePathEnum.Withdraw,
    meta: { title: "nav." + RoutePathEnum.Withdraw }
  },
  WithdrawAccountList: { // 同名提款 - 提款账户列表
    path: `/${RoutePathEnum.Withdraw}/accountList`,
    name: 'withdraw.accountList',
    meta: { title: "withdraw.accountList" }
  },
  WithdrawAccountAddEdit: { // 同名提款 - 添加提款账户
    path: `/${RoutePathEnum.Withdraw}/account/add`,
    name: 'withdraw.accountAddEdit',
    meta: { title: "withdraw.accountAddEdit" }
  },
  WithdrawAccountEdit: { // 同名提款 - 编辑提款账户
    path: `/${RoutePathEnum.Withdraw}/account/edit`,
    name: 'withdraw.accountEdit',
    meta: { title: "withdraw.accountEdit" }
  },
  Payout: {
    path: `/${RoutePathEnum.Payout}`,
    name: RoutePathEnum.Payout,
    meta: { title: "nav." + RoutePathEnum.Payout }
  },
  PayeeManagement: {
    path: `/${RoutePathEnum.Payout}/PayeeManagement`,
    name: 'Payout.PayeeManagement',
    meta: { title: "payout.payeeManagement" }
  },
  AddPayee: {
    path: `/${RoutePathEnum.Payout}/account/AddPayee`,
    name: 'Payout.AddPayee',
    meta: { title: "payout.addPayee" }
  },
  EditPayee: {
    path: `/${RoutePathEnum.Payout}/account/EditPayee`,
    name: 'Payout.EditPayee',
    meta: { title: "payout.editPayee" }
  },
  Financing: {
    path: `/${RoutePathEnum.Financing}`,
    name: RoutePathEnum.Financing,
    meta: { title: RoutePathEnum.Financing }
  },
  Transactions: {
    path: `/${RoutePathEnum.Transactions}`,
    name: RoutePathEnum.Transactions,
    meta: { title: RoutePathEnum.Transactions }
  }
}

export const rules: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/index"
  },
  {
    path: "/" + RoutePathEnum.Home,
    name: RoutePathEnum.Home,
    meta: { title: "nav." + RoutePathEnum.Home  },
    component: () => import("../../pages/HomePage/index.vue"),
  },
  {
    path: "/" + RoutePathEnum.Asserts,
    name: '',
    meta: { title: "nav." + RoutePathEnum.Asserts  },
    component: RouterMiddleware,
    children: [
      {
        path: '',
        name: RoutePathEnum.Asserts,
        meta: { title: "nav." + RoutePathEnum.Asserts  },
        component: () => import("../../pages/AssetCenter/AccountView.vue"),
      },
      {
        path: RoutePathMap.AssetsAccountList.path,
        name: '',
        meta: RoutePathMap.AssetsAccountList.meta,
        component: RouterMiddleware,
        children: [
          {
            path: '',
            name: RoutePathMap.AssetsAccountList.name,
            meta: RoutePathMap.AssetsAccountList.meta,
            component: () => import("../../pages/AssetCenter/AccountList.vue"),
          },
          {
            path: RoutePathMap.AssetsAccountDetail.path,
            name: RoutePathMap.AssetsAccountDetail.name,
            meta: RoutePathMap.AssetsAccountDetail.meta,
            component: () => import("../../pages/AssetCenter/AccountDetail.vue"),
          }
        ]
      },
    ]
  },
  {
    path: "/" + RoutePathEnum.Withdraw,
    name: '',
    meta: RoutePathMap.Withdraw.meta, // 需要写，面包屑的时候需要用到这个
    component: RouterMiddleware, // 默认布局
    children: [
      {
        path: '', // 当访问父路由，再router-view自动匹配到这里
        name: RoutePathMap.Withdraw.name, // 作为children name必须写 
        meta: RoutePathMap.Withdraw.meta,
        component: () => import("../../pages/Withdraw/WithdrawView.vue")
      },
      {
        path: RoutePathMap.WithdrawAccountList.path,
        name: '',
        meta: RoutePathMap.WithdrawAccountList.meta,
        component: RouterMiddleware,
        children: [
          {
            path: RoutePathMap.WithdrawAccountList.path,
            name: RoutePathMap.WithdrawAccountList.name,
            meta: RoutePathMap.WithdrawAccountList.meta,
            component: () => import("../../pages/Withdraw/AccountList.vue"),
          },
          {
            path: RoutePathMap.WithdrawAccountEdit.path,
            name: RoutePathMap.WithdrawAccountEdit.name,
            meta: RoutePathMap.WithdrawAccountEdit.meta,
            component: () => import("../../pages/Withdraw/AccountAddEdit.vue"),
          },
          {
            path: RoutePathMap.WithdrawAccountAddEdit.path,
            name: RoutePathMap.WithdrawAccountAddEdit.name,
            meta: RoutePathMap.WithdrawAccountAddEdit.meta,
            component: () => import("../../pages/Withdraw/AccountAddEdit.vue"),
          }
        ]
      }
    ]
  },
  {
    path: "/" + RoutePathEnum.Payout,
    name: '',
    meta: RoutePathMap.Payout.meta,
    component: RouterMiddleware,
    children: [
      {
        path: '',
        name: RoutePathMap.Payout.name,
        meta: RoutePathMap.Payout.meta,
        component: () => import("../../pages/Payout/PayoutView.vue"),
      },
      {
        path: RoutePathMap.PayeeManagement.path,
        name: '',
        meta: RoutePathMap.PayeeManagement.meta,
        component: RouterMiddleware,
        children: [
          {
            path: RoutePathMap.PayeeManagement.path,
            name: RoutePathMap.PayeeManagement.name,
            meta: RoutePathMap.PayeeManagement.meta,
            component: () => import("../../pages/Payout/PayeeManagementView.vue"),
          },
          {
            path: RoutePathMap.EditPayee.path,
            name: RoutePathMap.EditPayee.name,
            meta: RoutePathMap.EditPayee.meta,
            component: () => import("../../pages/Payout/AddPayeeView.vue"),
          },
          {
            path: RoutePathMap.AddPayee.path,
            name: RoutePathMap.AddPayee.name,
            meta: RoutePathMap.AddPayee.meta,
            component: () => import("../../pages/Payout/AddPayeeView.vue"),
          }
        ]
      }
    ]
  },
  {
    path: "/" + RoutePathEnum.Exchange,
    name: RoutePathEnum.Exchange,
    meta: { title: "nav." + RoutePathEnum.Exchange },
    component: () => import("../../pages/Exchange/ExchangeView.vue"),
  },
  {
    path: "/" + RoutePathEnum.Transactions,
    name: RoutePathEnum.Transactions,
    meta: { title: "nav." + RoutePathEnum.Transactions },
    component: () => import("../../pages/Transactions/TransactionsView.vue"),
  },
  {
    path: "/" + RoutePathEnum.Financing,
    name: RoutePathEnum.Financing,
    meta: { title: "nav." + RoutePathEnum.Financing },
    component: () => import("../../pages/FinancingView.vue"),
  },
  {
    path: "/" + RoutePathEnum.Rights,
    name: RoutePathEnum.Rights,
    meta: { title: "nav." + RoutePathEnum.Rights },
    component: () => import("../../pages/EquityCenter/index.vue"),
  },
  {
    path: "/" + RoutePathEnum.Settings,
    name: RoutePathEnum.Settings,
    meta: { title: "nav." + RoutePathEnum.Settings },
    component: () => import("../../pages/SettingsView.vue"),
  }
]

if (__DEV__) {
  // rules.push({
  //   path: "/admin/index",
  //   name: "admin",
  //   meta: { title: "nav.admin" },
  //   component: () => import("../../pages/admin/IndexView.vue"),
  // })

  rules.push({
    path: "/ui",
    name: "ui",
    meta: { title: "nav.ui" },
    component: () => import("../../pages/dev/UI.vue"),
  }, {
    path: "/precision",
    name: "precision",
    meta: { title: "nav.precision" },
    component: () => import("../../pages/dev/precision.vue"),
  }, {
    path: "/layout-default",
    name: "layout-default",
    meta: { title: "nav.layout.default" },
    component: () => import("../../pages/dev/LayoutDefault.vue"),
  }, {
    path: "/layout-detail",
    name: "layout-detail",
    meta: { title: "nav.layout.detail" },
    component: () => import("../../pages/dev/LayoutDetail.vue"),
  }, {
    path: "/layout-blank",
    name: "layout-blank",
    meta: { title: "nav.layout.blank" },
    component: () => import("../../pages/dev/LayoutBlank.vue"),
  })
}

export function getRouteList(): Array<{ path: string; name: RoutePathEnum; title: MessageKey; }> {
  return rules.filter(i => !!i.meta).map(item => {
    const name = item.name || item.children?.find(t => t.path === '')?.name
    return {
      path: item.path,
      name: name as RoutePathEnum,
      title: item.meta?.title as MessageKey
    }
  })
}

